html {
  font-family: '72', '72full', Arial, Helvetica, sans-serif;
  height: 100%;
}
html body {
  margin: 0;
  padding: 0;
  height: 100%;
}
.fd-spinner.page-load-spinner {
  margin-top: calc(50vh - 60px);
}
.fd-shell.fd-shell--fundamentals {
  position: relative;
  height: auto !important;
}
.fd-shell.fd-shell--fundamentals .fd-shell__app {
  min-height: calc(100vh - 44px);
  height: calc(100vh - 44px);
}
.fd-shell.fd-shell--fundamentals .fd-shell__app .fd-app .fd-app__navigation--vertical {
  max-width: 240px;
  overflow-y: auto;
  -webkit-box-flex: auto;
  -ms-flex: 0;
  flex: auto;
}
.fd-shell.fd-shell--fundamentals .fd-shell__app .fd-app > .hide-menu {
  width: 50px;
  max-width: 50px;
}
.fd-shell.fd-shell--fundamentals .fd-shell__app .fd-app > .fd-app__main > div {
  height: 100%;
}
.fd-shell.fd-shell--fundamentals .fd-shell__app .fd-app > .fd-app__main > div > div {
  height: 100%;
}
.fd-shell.fd-shell--fundamentals .fd-shell__app .fd-app > .fd-app__main > div > div > div {
  height: 100%;
}
.list-view .footer-bar,
.edit-view .footer-bar,
.detail-view .footer-bar,
.claims-invoice-review .footer-bar {
  left: 0;
  width: calc(100% - 1rem);
}
.list-view .fd-panel__body,
.edit-view .fd-panel__body,
.detail-view .fd-panel__body,
.claims-invoice-review .fd-panel__body {
  padding: 0;
}
.list-view .fd-panel__actions > button,
.edit-view .fd-panel__actions > button,
.detail-view .fd-panel__actions > button,
.claims-invoice-review .fd-panel__actions > button,
.list-view .fd-panel__actions .fd-button-group,
.edit-view .fd-panel__actions .fd-button-group,
.detail-view .fd-panel__actions .fd-button-group,
.claims-invoice-review .fd-panel__actions .fd-button-group {
  margin-left: 8px;
}
.detail-view .form-text,
.edit-view .form-text {
  color: var(--fd-color-text-1);
}
.detail-view .amount,
.edit-view .amount {
  color: #666666;
  font-family: '72', sans-serif;
  font-weight: 800;
  font-size: 2.2rem !important;
}
.detail-view .unit,
.edit-view .unit {
  color: #787777;
  font-family: '72', sans-serif;
  font-weight: bold;
  font-size: 1rem !important;
}
.detail-view .fd-tree__col--control,
.edit-view .fd-tree__col--control {
  width: 50px;
}
.detail-view .fd-tree__row--header,
.edit-view .fd-tree__row--header {
  background-color: #fff;
}
.fd-page .page-title {
  margin: 12px 0;
}
.sap-eureka-loading-cycle {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.fd-alert.fd-alert--dismissible.fd-alert--error {
  height: auto !important;
}
.fd-side-nav .fd-side-nav__list .fd-side-nav__item span.fd-side-nav__link {
  cursor: pointer;
}
[role=cell] {
  border-left: none !important;
  border-right: none !important;
}
.fd-menu.sap-eureka-common__header__notification {
  max-width: 600px;
}
.fd-menu.sap-eureka-common__header__notification .fd-menu__list {
  max-height: 300px;
  overflow: auto;
}
.logout-modal .fd-modal__body {
  width: 320px;
}
.fd-shell__header {
  height: 44px;
}
.fd-shell__header .fd-shellbar__group.fd-shellbar__group--product {
  margin-left: 32px;
}
.fd-shell__header .shellbar[breakpoint-size='L'] {
  padding-left: 1rem;
}
.fd-shell__header .shellbar[breakpoint-size='M'] {
  padding-left: 1rem;
}
.fd-shell__header .shellbar[breakpoint-size='S'] {
  padding-left: 2rem;
}
.fd-shell__header .toggle-sidenav-btn {
  position: absolute;
  top: 5px;
  left: 5px;
  color: #fff;
}
.fd-shell__header .toggle-sidenav-btn:hover {
  color: #0854a0;
}
/* Workaround to set the background color of avatar on Shellbar from fundamental-react */
.fd-has-background-color-accent-6,
.fd-identifier--thumbnail {
  background-color: #1973cc !important;
}
.fd-side-nav .fd-nested-list .level-2 .fd-nested-list__link {
  padding-left: 0;
}
.fd-side-nav .fd-side-nav__top {
  height: 46px;
  padding: 5px 0;
}
.fd-side-nav .fd-side-nav__top .fd-search-input {
  display: inline-block;
}
.fd-side-nav .fd-side-nav__top .sap-icon--m {
  font-size: 1rem;
  margin: 3px;
  margin-top: 5px;
  color: #89919a;
  cursor: pointer;
}
.fd-side-nav .fd-side-nav__top .sap-icon--m::before {
  font-size: 1.5rem;
  /* margin-top: 5px; */
  top: 10px;
  position: absolute;
}
.fd-side-nav .fd-side-nav__top .sap-icon--m.sap-icon--media-play {
  margin-left: 22px;
}
.home-welcome-page {
  text-align: center;
}
.home-welcome-page .app {
  text-align: center;
}
.home-welcome-page .rekit-logo {
  animation: app-logo-spin infinite 10s linear;
  height: 80px;
}
.home-welcome-page .app-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
  overflow: hidden;
  position: relative;
}
.home-welcome-page .app-title {
  font-size: 1.5em;
}
.home-welcome-page .app-intro {
  font-size: large;
}
.home-welcome-page ul,
.home-welcome-page li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.home-welcome-page ul {
  margin-top: 20px;
}
.home-welcome-page li {
  margin-top: 10px;
}
.home-welcome-page a {
  color: #0288d1;
  text-decoration: none;
}
.home-welcome-page a:hover {
  text-decoration: underline;
}
.home-welcome-page p.memo {
  width: 500px;
  color: #999;
  font-size: 12px;
  line-height: 150%;
  margin: auto;
}
@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.home-page .fd-section__header {
  margin-bottom: 0;
}
.home-page .fd-section__header .fd-section__title {
  font-size: 1.28578rem;
}
.home-page .fd-panel.icon-tile {
  cursor: pointer;
  min-width: 220px;
  height: 220px;
}
.home-page .fd-panel.icon-tile .fd-panel__header {
  border-bottom: 0;
}
.home-page .fd-panel.icon-tile .fd-panel__body {
  border-bottom: 0;
  position: relative;
  height: 170px;
}
.home-page .fd-panel.icon-tile .fd-panel__body .tile-text {
  margin-left: 22px;
  font-size: 14px;
  color: #32363a;
}
.home-page .fd-panel.icon-tile .sap-icon {
  color: #5a7ca0;
  display: block;
  position: absolute;
  bottom: 20px;
  left: 25px;
}
.home-page .fd-panel.icon-tile .sap-icon:before {
  font-size: 36px;
}
.home-page .fd-panel.chart-tile .fd-panel__title {
  margin-top: -38px;
}
.home-page .fd-panel.chart-tile .currency {
  text-align: right;
}
.home-page .fd-panel.chart-tile .currency .amount {
  color: #107e3e;
  font-family: '72', sans-serif;
  font-weight: 300;
  font-size: 3.5rem !important;
}
.home-page .fd-panel.chart-tile .currency .unit {
  color: #107e3e;
  font-family: '72', sans-serif;
  font-weight: 700;
  font-size: 1rem !important;
}
.home-page .fd-panel.chart-tile .chart-desc {
  margin: 10px 0 10px 16px;
  font-size: 1rem;
}
.home-page .fd-panel__body {
  padding: 8px;
}
.home-page .fd-panel__body.no-padding {
  padding: 0;
}
.home-page .fd-panel__body .fd-product-tile__content {
  padding: 16px 24px;
}
.home-page .fd-panel__body .embed-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#scaling-container,
#scaling-content,
#scaling-app {
  height: 100%;
}

.l1exdy1u{height:135px;padding:0 0.2rem;}
.h1oje4nh{border-bottom:0.0625rem solid var(--sapPageFooter_BorderColor,#d9d9d9);padding:0 0.5rem;}
.c2fz87j{padding:1rem;}
.fvmbl9m{border-top:0.0625rem solid var(--sapPageFooter_BorderColor,#d9d9d9);padding:0.2rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;}
